.resume{
    min-width: 800px;
    min-height: 500px;
    border: 0;

}

@media screen and (max-width: 700px) {
    .resume{
        display: none;
    }
}


.intro{
    display: grid;
    padding: 20px;
    backdrop-filter: saturate(50%) blur(20px);
    border-radius: 10px;
    box-shadow: 0 0 2cap rgb(188, 188, 188);

    @media only screen and (max-width: 400px){
        font-size: .8rem;
        zoom: .8;
    }
}

