@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono&display=swap');
body{
    margin: 0;
    font-family: 'Noto Sans Mono', monospace;
    background-color: #ece2d0;
}

.container{
    height: 100vh;
    
    
}

::-webkit-scrollbar{
    width: 6px;
}
::-webkit-scrollbar-thumb{
    border-radius: 10px;
    width: 5px;
    background-color: #7C7C7C;
}

.center{
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100%;

}