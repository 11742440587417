.footer{
    flex:1;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 15px;
    font-size: .8rem;
  }
  
  .footer a{
    text-decoration: none;
    color: black;
    text-shadow: 0 0 10px black;
  }
  
  .footer a:hover{
    color: rgb(169, 169, 169)
  }
  
  /* 
  justicon-icon pack, credits goes to all authors
  */
  .img{
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .img:hover{
    
    transform: translateY(-5px);
  }

  .icon-shelf{
    display: flex;
    gap: 10px;
  }